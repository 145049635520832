import i18n from "i18next";
import { initReactI18next } from "react-i18next";

//import LanguageDetector from "i18next-browser-languagedetector";

const LanguageDetector = {
  type: "languageDetector",
  detect: () => {
    if (window.location.hostname === "jupol-izziv.jub.si") {
      return "sl";
    } else if (window.location.hostname === "jupol-kviz.jub.rs") {
      return "rs";
    } else if (window.location.hostname === "jupol-kviz-ba.escapebox.si") {
      return "ba";
    } else if (window.location.hostname === "jupol-kviz.jub.hr") {
      return "hr";
    }
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)

  .init({
    fallbackLng: "rs",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    resources: {
      sl: {
        translations: require("./locales/sl/translations.json"),
      },
      rs: {
        translations: require("./locales/rs/translations.json"),
      },
      ba: {
        translations: require("./locales/ba/translations.json"),
      },
      hr: {
        translations: require("./locales/hr/translations.json"),
      },
    },
  });

export default i18n;
